<template>
  <div class="main-content">
    <router-view  />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>
