var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm.isOver
      ? _c(
          "div",
          { staticClass: "menu" },
          [_c("router-link", { attrs: { to: "/nyar" } }, [_vm._v("Nyár")])],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("img", {
        attrs: { src: require("@/assets/img/hero.jpg"), alt: "hero image" },
      }),
      _c("i", { staticClass: "icon logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }