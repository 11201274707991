var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home-page" },
    [
      _c("Header", { attrs: { isOver: _vm.isRegistrationOver } }),
      _c("router-view", { attrs: { isOver: _vm.isRegistrationOver } }),
      _c("Footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }