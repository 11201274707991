<template>
  <div class="home-page">
    <Header :isOver="isRegistrationOver" />
    <router-view :isOver="isRegistrationOver"></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer,
  },

  computed: {
    isRegistrationOver() {
      let currentDate = new Date();
      let endDate = new Date(2023, 7, 1);
      if (endDate >= currentDate) {
        return true;
      }
      return false;
    },
  },
};
</script>
