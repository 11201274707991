var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "success" },
    [_c("Header"), _vm._m(0), _c("Footer")],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thank-you" }, [
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("Köszönjük a jelentkezésedet!"),
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v(" A további információkat e-mailben küldtük el neked! "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }