<template>
  <div class="coach info">
    <div class="container">
      <div
        class="img"
        :class="{ 'no-center': name == 'Gregori Dóra' }"
        :style="{ backgroundImage: 'url(' + image + ')' }"
      ></div>
      <div class="textual">
        <div class="header">
          <span class="name">{{ name }}</span>
          <a :href="instagram" target="_blank" class="icon-container"
            ><i class="icon share--instagram"
          /></a>
        </div>
        <div class="text">{{ text }}</div>
      </div>
      <div class="close"><i class="icon exit" @click="$emit('close')" /></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CoachInfo",
  props: {
    name: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    instagram: {
      type: String,
      required: true,
    },
  },
};
</script>
