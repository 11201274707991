var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("i", { staticClass: "icon logo" }),
        _c("div", { staticClass: "textual" }, [
          _c("span", [
            _vm._v(
              "További információkért keress bennünket az alábbi elérhetőségeken"
            ),
          ]),
          _c(
            "a",
            {
              staticClass: "email",
              attrs: { href: "mailto:futotabor@runnersworld.hu" },
            },
            [_vm._v("futotabor@runnersworld.hu")]
          ),
          _c("div", { staticClass: "tel" }, [
            _c("a", { attrs: { href: "tel:+36 30 8336757" } }, [
              _vm._v("+36 30 8336757"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "share" }, [
          _c("span", [_vm._v("Kövess minket!")]),
          _c("div", { staticClass: "icons" }, [
            _c(
              "a",
              {
                staticClass: "icon-container",
                attrs: {
                  href: "https://www.facebook.com/runnersworldmagyarorszag",
                  target: "_blank",
                },
              },
              [_c("i", { staticClass: "icon share--facebook" })]
            ),
            _c(
              "a",
              {
                staticClass: "icon-container",
                attrs: {
                  href: "https://www.instagram.com/runnersworld_hu/",
                  target: "_blank",
                },
              },
              [_c("i", { staticClass: "icon share--instagram" })]
            ),
            _c(
              "a",
              {
                staticClass: "icon-container",
                attrs: {
                  href: "https://www.youtube.com/@runnersworldde",
                  target: "_blank",
                },
              },
              [_c("i", { staticClass: "icon share--youtube" })]
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }