var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOver
    ? _c(
        "div",
        { staticClass: "page current-event" },
        [
          _vm._m(0),
          _vm._m(1),
          _c("coach-popup"),
          _vm._m(2),
          _vm._m(3),
          _c("div", { staticClass: "closed" }, [
            _vm._v("A jelentkezés lezárult"),
          ]),
          _vm._m(4),
          _c("div", { staticClass: "closed" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "divider" }),
              _c("div", { staticClass: "title" }, [
                _vm._v("ÍME EGY KIS ÍZELÍTŐ AZ EDDIGI TÁBOROKBÓL:"),
              ]),
              _c("div", { staticClass: "videos" }, [
                _c("div", { ref: "scriptHolder" }),
              ]),
            ]),
          ]),
        ],
        1
      )
    : _c("closed")
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "first" }, [
      _c("div", { staticClass: "section green" }, [
        _c("div", { staticClass: "divider" }),
        _c("span", [
          _vm._v(
            " SZERETETTEL VÁRUNK A RUNNER’S WORLD NYÁRI FUTÓTÁBORÁBA, AMI MÁR A 15.LESZ A SORBAN! "
          ),
        ]),
        _c("span", [_vm._v(" 2023. június 29 – július 2. ")]),
      ]),
      _c("div", { staticClass: "section black" }, [
        _c("span", { staticClass: "article" }, [
          _vm._v(
            "A Runner’s World Futótáborban mindenki megtalálja a számításait. Várjuk mindazokat, akik szeretnének fejlődni ebben a sportban vagy épp megismerni és tagjává válni egy nagyszerű futóközösségnek."
          ),
        ]),
        _c("span", { staticClass: "article join" }, [
          _vm._v("Csatlakozz a futótáborunkhoz!"),
        ]),
        _c("span", { staticClass: "article" }, [
          _vm._v(
            " Lehetsz kezdő vagy fanatikus futó, szakértőink és edzőink segítségével olyan gyakorlati tanácsokra tehetsz szert, amelyekkel elkerülheted a sérüléseket, javíthatod a teljesítményedet, és könnyebben elérheted a céljaidat! "
          ),
        ]),
        _c("span", { staticClass: "article" }, [
          _vm._v(
            "Ne aggódj, ha 3 kilométeren kiköpöd a tüdőd, viszont, ha maratonra készülsz, Futótáborunkban akkor is megtalálod számításaid. Szuper edzőkkel készülünk, hogy edzettségi szintednek megfelelő terhelést kapj!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "second" }, [
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "title" }, [
        _vm._v("Hogy mire számíthatsz a táborban?"),
      ]),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("EGY KIS ÍZELÍTŐ A PROGRAMOKBÓL:"),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("ul", [
          _c("li", [_vm._v("Napi két futóedzés")]),
          _c("li", [_vm._v("Kiegészítő edzések: erősítés, jóga, nyújtás")]),
          _c("li", [_vm._v("Szakmai tanácsadás és mozgáselemzés")]),
          _c("li", [_vm._v("Workshopok, izgalmas előadások")]),
        ]),
        _c("ul", [
          _c("li", [_vm._v("Lábdiagnosztika és cipőtesztelés")]),
          _c("li", [_vm._v("Táplálkozási és egészséges életmód tanácsadás")]),
          _c("li", [_vm._v("Regeneráló fürdőzés, wellness élmények")]),
          _c("li", [_vm._v("Meglepetés vendég, ajándékok")]),
        ]),
      ]),
      _c("div", { staticClass: "footnote" }, [
        _vm._v("A változtatás jogát fenntartjuk."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fourth" }, [
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "title" }, [_vm._v("A tábor szállásadója")]),
          _c("div", { staticClass: "subtitle" }, [
            _vm._v("MINARO HOTEL TOKAJ***** MGALLERY"),
          ]),
          _c("div", { staticClass: "text-container" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " Az Accor Hotels MGallery Collection második magyar tagja az 5 csillagos Minaro Hotel, amely Tokaj szőlődombjai között épült, az UNESCO Világörökség részét képező Tokaji borvidék területén."
              ),
              _c("br"),
              _vm._v(
                " A minimalista dizájnt az egykori bányakörnyezet inspirálta, ám az impozáns belső térben a természet kincsei és a Maya világ fényűző elemei élednek újjá."
              ),
              _c("br"),
              _vm._v(
                " A Minaro szálloda elnevezését Tokaj folyékony aranya (oro) és a szálloda elhelyezkedése (mine) ihlette."
              ),
              _c("br"),
              _vm._v(
                " A luxus szálloda 100 szobával és lenyűgöző kilátással fogadja a vendégeket a tokaji borvidék érintetlen környezetének a közepén, melyek mindegyike valóságos ékszerdoboz."
              ),
              _c("br"),
              _vm._v(
                " Akár szabadidős, akár üzleti célú utazásról van szó, a pihenés garantált. Minden szoba a legmagasabb színvonalon lett kialakítva és dekorálva, merítve az inka kőbányászatból és az ősi mitológiából."
              ),
              _c("br"),
              _vm._v(" A fürdőszobákat az esőerdő ihlette."),
              _c("br"),
              _vm._v(
                " A Tortuga Étterem a kulináris művészetet ötvözi a legjobb alapanyagokkal, köztük a helyi „folyékony arannyal”, a tokaji borral."
              ),
              _c("br"),
              _vm._v(
                " Az étterem kifinomult környezete verhetetlen választássá teszi az emlékezetes tokaji étkezési élményt."
              ),
              _c("br"),
              _vm._v(
                " Az egyedülálló étlapot az ősi inka hagyományok, valamint a nap, a hold, a föld, az ég és az élet imádata ihlette. "
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " Felfedezheted az MGallery mixológiát, ha ellátogatsz a Bar Viracochaba egy koktélra, amelyet a nélkülözhetetlen szőlő alapanyag inspirált. Egészen luxus kategóriáig választható a Tequila vagy a jellegzetes amerikai rum, valamint jellegzetes helyi, tokaji bor rendelhető. Kényeztesd a tested és lelked fényűző környezetben a L’OCCITANE egyedülálló natúrkozmetikumaival a legmagasabb minőségben! A L’OCCITANE fürdőkezelései nem csak nőknek, hanem kifejezetten férfiak számára készült kezeléseket is kínál. "
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " Wellness szolgáltatások között fűtött, fedett úszómedence, szabadtéri élménymedence, valamint egész évben használható kültéri jacuzzi és wellness relaxációs területek is találhatók. A Sókamrák, szaunák, kezelőszobák és professzionális kezelések megkönnyítik a nyugalom megtalálását. "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "right" }, [
          _c(
            "a",
            {
              attrs: {
                href: "https://www.minaromgallery.hu/",
                target: "_blank",
              },
            },
            [
              _c("img", {
                staticClass: "logo",
                attrs: {
                  src: require("@/assets/img/minaro-hotel/minaro_logo.webp"),
                  alt: "MINARO HOTEL TOKAJ",
                },
              }),
            ]
          ),
          _c("img", {
            attrs: {
              src: require("@/assets/img/minaro-hotel/epulet.jpeg"),
              alt: "MINARO HOTEL TOKAJ",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fifth" }, [
      _c("div", { staticClass: "section black" }, [
        _c("div", { staticClass: "option" }, [
          _c("div", { staticClass: "price" }, [
            _vm._v("120 000 Ft/fő/4 nap/3 éj - félpanzióval"),
          ]),
          _vm._v(" kétágyas elhelyezés esetén "),
        ]),
        _c("div", { staticClass: "option" }, [
          _c("div", { staticClass: "price" }, [
            _vm._v("240 000 Ft/fő/4 nap/3 éj - félpanzióval"),
          ]),
          _vm._v(" egyágyas elhelyezés esetén "),
        ]),
        _c("div", { staticClass: "divider" }),
        _c("div", { staticClass: "option" }, [
          _c("div", { staticClass: "price" }, [
            _vm._v("40.000 Ft/fő/4 nap - szakmai csomag"),
          ]),
          _vm._v(
            " Kizárólag a programokon való részvételt tartalmazza, szállás és étkezés nélkül! Helyi futóknak, környéken élőknek ajánljuk. "
          ),
        ]),
        _c("div", { staticClass: "disclaimer" }, [
          _vm._v(
            " A fenti árak nem tartalmazzák az idegenforgalmi adót, amelynek mértéke 18 év felett 350 Ft/fő/éj. "
          ),
        ]),
      ]),
      _c("div", { staticClass: "section green" }, [
        _c("div", { staticClass: "subtitle" }, [_vm._v("Az ár tartalmazza:")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              " Szállás a MINARO HOTEL TOKAJ***** MGALLERY, Superior szobáiban "
            ),
          ]),
          _c("li", [_vm._v("Minimalista design, luxus környezet")]),
          _c("li", [
            _vm._v(
              " Félpanziós ellátás: svédasztalos reggeli és vacsora a Tortuga étteremben (Az ebédet és extra italfogyasztást az ajánlat nem tartalmazza - igény szerint egyénileg rendelhető a hotel étlapjáról) "
            ),
          ]),
          _c("li", [_vm._v("Üdvözlő ital a Viracocha Bárban")]),
          _c("li", [
            _vm._v(
              " Belépést az L’OCCITANE SPA részelgünkbe, amely felszerelt fűtött beltéri medencével, kültéri élmény medencével, jacuzzival, sószobával, oxigénkabinnal és szaunákkal. "
            ),
          ]),
          _c("li", [_vm._v("L’Occitane fürdőszobai kellékek")]),
          _c("li", [_vm._v("A szobában ingyenes ásványvíz bekészítés")]),
          _c("li", [_vm._v("Kávé/Tea készítési lehetőség")]),
          _c("li", [_vm._v("Nespresso kávégép")]),
          _c("li", [_vm._v("Vezeték nélküli internetet a szobákban")]),
          _c("li", [_vm._v("Ingyenes parkolás")]),
          _c("li", [_vm._v("FUTÓEDZÉSEK napi 2 alkalommal")]),
          _c("li", [_vm._v("KERESZTEDZÉSEK és egyéb futóprogramok")]),
          _c("li", [
            _vm._v("Izgalmas WORKSHOPOK és előadások neves szakemberekkel"),
          ]),
          _c("li", [_vm._v("RW technikai FUTÓPÓLÓ minden résztvevőnek")]),
          _c("li", [_vm._v("RW ELISMERÉS minden táborlakónak")]),
          _c("li", [_vm._v("RW meglepetés AJÁNDÉKCSOMAG")]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sponsor" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "divider" }),
        _c("div", { staticClass: "title" }, [_vm._v("Támogatóink")]),
      ]),
      _c("i", { staticClass: "icon garmin-logo" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }