<template>
  <div class="success">
    <Header />
    <div class="thank-you">
      <div class="divider" />
      <div class="title">Köszönjük a jelentkezésedet!</div>
      <div class="subtitle">
        A további információkat e-mailben küldtük el neked!
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Success",

  components: {
    Header,
    Footer,
  },
};
</script>
