import("./assets/scss/styles.scss");

/* IMPORT SCRIPTS */
import Vue from "vue";
import { router } from "@/router";
import { store } from "@/store";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
Vue.component("vue-toast", VueToast);
Vue.use(VueToast, {
  position: "bottom-right",
  duration: 5000,
  dismissible: true,
});

import { Settings } from "luxon";
Settings.defaultLocale = "hu";

import App from "./App.vue";

Vue.config.productionTip = false;

window.AppInstance = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
