var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.showCoach
    ? _c("div", { staticClass: "coach popup" }, [
        _c("div", { staticClass: "container" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "coaches" },
            _vm._l(_vm.coaches, function (coach) {
              return _c("div", { key: coach.id, staticClass: "coach" }, [
                _c("div", {
                  staticClass: "img",
                  style: { backgroundImage: "url(" + coach.image + ")" },
                }),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(coach.name)),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "popout-button",
                    on: {
                      click: function ($event) {
                        return _vm.showInfo(coach.id)
                      },
                    },
                  },
                  [_vm._v("Megismerem őt!")]
                ),
              ])
            }),
            0
          ),
        ]),
      ])
    : _c("coach-info", {
        attrs: {
          name: _vm.whichCoach.name,
          image: _vm.whichCoach.image,
          text: _vm.whichCoach.text,
          instagram: _vm.whichCoach.instagram,
        },
        on: {
          close: function ($event) {
            return _vm.hideInfo()
          },
        },
      })
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-container" }, [
      _c("div", { staticClass: "divider" }),
      _c("div", { staticClass: "title" }, [_vm._v("Vezetőedzőink")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }