var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "closed" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "divider" }),
      _vm._m(0),
      _c("div", { staticClass: "subtitle" }, [
        _vm._v("ÍME EGY KIS ÍZELÍTŐ AZ EDDIGI TÁBOROKBÓL:"),
      ]),
      _c("div", { staticClass: "videos" }, [
        _c("div", { ref: "scriptHolder" }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _vm._v("Nyári táborunk időpontja: "),
      _c("br"),
      _vm._v("2023. június 29 - július 2. "),
      _c("br"),
      _c("br"),
      _vm._v("Részletek HAMAROSAN!"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }