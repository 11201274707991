<template>
  <div class="closed">
    <div class="container">
      <div class="divider" />
      <div class="title">Nyári táborunk időpontja: <br>2023. június 29 - július 2. <br><br>Részletek HAMAROSAN!</div>
      <div class="subtitle">ÍME EGY KIS ÍZELÍTŐ AZ EDDIGI TÁBOROKBÓL:</div>
      <div class="videos">
        <div ref="scriptHolder"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Closed",
  mounted() {
    const scriptHolder = this.$refs.scriptHolder;
    const player = document.createElement("div");
    player.setAttribute(
      "id",
      "summit-player--ee59ded4-0a4d-4bb4-9ed1-fb848d94a71a"
    );
    scriptHolder.appendChild(player);

    const script = document.createElement("script");
    script.setAttribute("src", "https://player.summitplayer.com/embed.js");
    script.setAttribute("defer", "");
    scriptHolder.appendChild(script);

    window.summitplayer = window.summitplayer || { players: {} };
    window.summitplayer.players["ee59ded4-0a4d-4bb4-9ed1-fb848d94a71a"] = {
      type: "playlist",
      autoplay: true,
      muted: true,
    };
  },
};
</script>
