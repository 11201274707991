<template>
  <div class="page current-event" v-if="isOver">
    <div class="first">
      <div class="section green">
        <div class="divider" />
        <span>
          SZERETETTEL VÁRUNK A RUNNER’S WORLD NYÁRI FUTÓTÁBORÁBA, AMI MÁR A
          15.LESZ A SORBAN!
        </span>
        <span> 2023. június 29 – július 2. </span>
      </div>
      <div class="section black">
        <span class="article"
          >A Runner’s World Futótáborban mindenki megtalálja a számításait.
          Várjuk mindazokat, akik szeretnének fejlődni ebben a sportban vagy épp
          megismerni és tagjává válni egy nagyszerű futóközösségnek.</span
        >
        <span class="article join">Csatlakozz a futótáborunkhoz!</span>
        <span class="article">
          Lehetsz kezdő vagy fanatikus futó, szakértőink és edzőink segítségével
          olyan gyakorlati tanácsokra tehetsz szert, amelyekkel elkerülheted a
          sérüléseket, javíthatod a teljesítményedet, és könnyebben elérheted a
          céljaidat!
        </span>
        <span class="article"
          >Ne aggódj, ha 3 kilométeren kiköpöd a tüdőd, viszont, ha maratonra
          készülsz, Futótáborunkban akkor is megtalálod számításaid. Szuper
          edzőkkel készülünk, hogy edzettségi szintednek megfelelő terhelést
          kapj!</span
        >
      </div>
    </div>
    <div class="second">
      <div class="divider" />
      <div class="title">Hogy mire számíthatsz a táborban?</div>
      <div class="subtitle">EGY KIS ÍZELÍTŐ A PROGRAMOKBÓL:</div>
      <div class="content">
        <ul>
          <li>Napi két futóedzés</li>
          <li>Kiegészítő edzések: erősítés, jóga, nyújtás</li>
          <li>Szakmai tanácsadás és mozgáselemzés</li>
          <li>Workshopok, izgalmas előadások</li>
        </ul>
        <ul>
          <li>Lábdiagnosztika és cipőtesztelés</li>
          <li>Táplálkozási és egészséges életmód tanácsadás</li>
          <li>Regeneráló fürdőzés, wellness élmények</li>
          <li>Meglepetés vendég, ajándékok</li>
        </ul>
      </div>
      <div class="footnote">A változtatás jogát fenntartjuk.</div>
    </div>

    <!--Third-->
    <coach-popup />

    <div class="fourth">
      <div class="divider" />
      <div class="content">
        <div class="left">
          <div class="title">A tábor szállásadója</div>
          <div class="subtitle">MINARO HOTEL TOKAJ***** MGALLERY</div>
          <div class="text-container">
            <div class="text">
              Az Accor Hotels MGallery Collection második magyar tagja az 5
              csillagos Minaro Hotel, amely Tokaj szőlődombjai között épült, az
              UNESCO Világörökség részét képező Tokaji borvidék területén.<br />
              A minimalista dizájnt az egykori bányakörnyezet inspirálta, ám az
              impozáns belső térben a természet kincsei és a Maya világ fényűző
              elemei élednek újjá.<br />
              A Minaro szálloda elnevezését Tokaj folyékony aranya (oro) és a
              szálloda elhelyezkedése (mine) ihlette.<br />
              A luxus szálloda 100 szobával és lenyűgöző kilátással fogadja a
              vendégeket a tokaji borvidék érintetlen környezetének a közepén,
              melyek mindegyike valóságos ékszerdoboz.<br />
              Akár szabadidős, akár üzleti célú utazásról van szó, a pihenés
              garantált. Minden szoba a legmagasabb színvonalon lett kialakítva
              és dekorálva, merítve az inka kőbányászatból és az ősi
              mitológiából.<br />
              A fürdőszobákat az esőerdő ihlette.<br />
              A Tortuga Étterem a kulináris művészetet ötvözi a legjobb
              alapanyagokkal, köztük a helyi „folyékony arannyal”, a tokaji
              borral.<br />
              Az étterem kifinomult környezete verhetetlen választássá teszi az
              emlékezetes tokaji étkezési élményt.<br />
              Az egyedülálló étlapot az ősi inka hagyományok, valamint a nap, a
              hold, a föld, az ég és az élet imádata ihlette.
            </div>
            <div class="text">
              Felfedezheted az MGallery mixológiát, ha ellátogatsz a Bar
              Viracochaba egy koktélra, amelyet a nélkülözhetetlen szőlő
              alapanyag inspirált. Egészen luxus kategóriáig választható a
              Tequila vagy a jellegzetes amerikai rum, valamint jellegzetes
              helyi, tokaji bor rendelhető. Kényeztesd a tested és lelked
              fényűző környezetben a L’OCCITANE egyedülálló natúrkozmetikumaival
              a legmagasabb minőségben! A L’OCCITANE fürdőkezelései nem csak
              nőknek, hanem kifejezetten férfiak számára készült kezeléseket is
              kínál.
            </div>
            <div class="text">
              Wellness szolgáltatások között fűtött, fedett úszómedence,
              szabadtéri élménymedence, valamint egész évben használható kültéri
              jacuzzi és wellness relaxációs területek is találhatók. A
              Sókamrák, szaunák, kezelőszobák és professzionális kezelések
              megkönnyítik a nyugalom megtalálását.
            </div>
          </div>
        </div>
        <div class="right">
          <a href="https://www.minaromgallery.hu/" target="_blank"
            ><img
              class="logo"
              src="@/assets/img/minaro-hotel/minaro_logo.webp"
              alt="MINARO HOTEL TOKAJ"
          /></a>

          <img
            src="@/assets/img/minaro-hotel/epulet.jpeg"
            alt="MINARO HOTEL TOKAJ"
          />
        </div>
      </div>
      <!--<div class="video-teaser">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/MdQ9nYSiwLw"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>-->
    </div>
    <div class="fifth">
      <div class="section black">
        <div class="option">
          <div class="price">120 000 Ft/fő/4 nap/3 éj - félpanzióval</div>
          kétágyas elhelyezés esetén
        </div>
        <div class="option">
          <div class="price">240 000 Ft/fő/4 nap/3 éj - félpanzióval</div>
          egyágyas elhelyezés esetén
        </div>
        <!--<div class="disclaimer visible">
          A szállodában lehetőség van SZÉP-kártyával történő fizetésre is! (OTP,
          K&H, MKB)
        </div>-->
        <div class="divider" />
        <div class="option">
          <div class="price">40.000 Ft/fő/4 nap - szakmai csomag</div>
          Kizárólag a programokon való részvételt tartalmazza, szállás és
          étkezés nélkül! Helyi futóknak, környéken élőknek ajánljuk.
        </div>
        <div class="disclaimer">
          A fenti árak nem tartalmazzák az idegenforgalmi adót, amelynek mértéke
          18 év felett 350 Ft/fő/éj.
        </div>
      </div>
      <div class="section green">
        <div class="subtitle">Az ár tartalmazza:</div>
        <ul>
          <li>
            Szállás a MINARO HOTEL TOKAJ***** MGALLERY, Superior szobáiban
          </li>
          <li>Minimalista design, luxus környezet</li>
          <li>
            Félpanziós ellátás: svédasztalos reggeli és vacsora a Tortuga
            étteremben (Az ebédet és extra italfogyasztást az ajánlat nem
            tartalmazza - igény szerint egyénileg rendelhető a hotel étlapjáról)
          </li>
          <li>Üdvözlő ital a Viracocha Bárban</li>
          <li>
            Belépést az L’OCCITANE SPA részelgünkbe, amely felszerelt fűtött
            beltéri medencével, kültéri élmény medencével, jacuzzival,
            sószobával, oxigénkabinnal és szaunákkal.
          </li>
          <li>L’Occitane fürdőszobai kellékek</li>
          <li>A szobában ingyenes ásványvíz bekészítés</li>
          <li>Kávé/Tea készítési lehetőség</li>
          <li>Nespresso kávégép</li>
          <li>Vezeték nélküli internetet a szobákban</li>
          <li>Ingyenes parkolás</li>
          <li>FUTÓEDZÉSEK napi 2 alkalommal</li>
          <li>KERESZTEDZÉSEK és egyéb futóprogramok</li>
          <li>Izgalmas WORKSHOPOK és előadások neves szakemberekkel</li>
          <li>RW technikai FUTÓPÓLÓ minden résztvevőnek</li>
          <li>RW ELISMERÉS minden táborlakónak</li>
          <li>RW meglepetés AJÁNDÉKCSOMAG</li>
        </ul>
      </div>
    </div>

    <!--<input-form />-->
    <div class="closed">A jelentkezés lezárult</div>

    <div class="sponsor">
      <div class="left">
        <div class="divider" />
        <div class="title">Támogatóink</div>
      </div>
      <!--<i class="icon puma-logo" />-->
      <i class="icon garmin-logo" />
    </div>
    <div class="closed">
      <div class="container">
        <div class="divider" />
        <div class="title">ÍME EGY KIS ÍZELÍTŐ AZ EDDIGI TÁBOROKBÓL:</div>
        <div class="videos">
          <div ref="scriptHolder"></div>
        </div>
      </div>
    </div>
  </div>
  <closed v-else />
</template>
<script>
//import InputForm from "@/components/InputForm.vue";
import CoachPopup from "@/components/CoachPopup.vue";
import Closed from "@/components/Closed.vue";
export default {
  components: {
    //InputForm,
    CoachPopup,
    Closed,
  },
  props: {
    isOver: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    const scriptHolder = this.$refs.scriptHolder;
    const player = document.createElement("div");
    player.setAttribute(
      "id",
      "summit-player--ee59ded4-0a4d-4bb4-9ed1-fb848d94a71a"
    );
    scriptHolder.appendChild(player);

    const script = document.createElement("script");
    script.setAttribute("src", "https://player.summitplayer.com/embed.js");
    script.setAttribute("defer", "");
    scriptHolder.appendChild(script);

    window.summitplayer = window.summitplayer || { players: {} };
    window.summitplayer.players["ee59ded4-0a4d-4bb4-9ed1-fb848d94a71a"] = {
      type: "playlist",
      autoplay: true,
      muted: true,
    };
  },
};
</script>
