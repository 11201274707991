<template>
  <div>
    <div class="header">
      <img src="~@/assets/img/hero.jpg" alt="hero image" />
      <i class="icon logo" />
    </div>
    <div v-if="isOver" class="menu">
      <router-link to="/nyar">Nyár</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOver: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
