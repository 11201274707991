<template>
  <div class="coach popup" v-if="!showCoach">
    <div class="container">
      <div class="title-container">
        <div class="divider" />
        <div class="title">Vezetőedzőink</div>
      </div>
      <div class="coaches">
        <div class="coach" v-for="coach in coaches" :key="coach.id">
          <div
            class="img"
            :style="{ backgroundImage: 'url(' + coach.image + ')' }"
          ></div>

          <span class="name">{{ coach.name }}</span>
          <span class="popout-button" @click="showInfo(coach.id)"
            >Megismerem őt!</span
          >
        </div>
        
      </div>
    </div>
  </div>
  <coach-info
    v-else
    :name="whichCoach.name"
    :image="whichCoach.image"
    :text="whichCoach.text"
    :instagram="whichCoach.instagram"
    @close="hideInfo()"
  />
</template>

<script>
import CoachInfo from "@/components/CoachInfo.vue";
import markocsan from "@/assets/img/current-event/markocsan-sandor.jpeg";
import godofanka from "@/assets/img/current-event/godofanka.jpg";
//import gregori from "@/assets/img/current-event/gregori-dora.jpg";
//import pajor from "@/assets/img/current-event/pajor-gabor.jpg";
export default {
  name: "CoachPopup",
  components: {
    CoachInfo,
  },
  data: () => ({
    showCoach: false,
    whichCoach: null,
    coaches: [
      {
        id: 1,
        name: "Markocsán Sándor „Sanyosz”",
        image: markocsan,
        text: '15 éve edző, a Sanyoszistálló egyesület elnöke, vezetőedzője, jelenleg több mint 40 tanítványa van.\nA RW indulása óta rendszeresen jelennek meg szakcikkei a magazinban, rendszeres szakértő.\nAz RW Futótáborok indulásától "kihagyhatatlan" edző!\nLegjobb eredmények: Többszörös korosztályos és csapat magyar bajnok\n5km: 14:05  I  10km: 29:17  I  FM: 65:42  I  Maraton 2:39:05',
        instagram: "https://instagram.com/sanyosz?igshid=YmMyMTA2M2Y=",
      },
      {
        id: 2,
        name: "Godó Fanka",
        image: godofanka,
        text: "Sport Pre/-Rehabilitációs edző, fitmummy Pre és postnatális tréner, emellett futóedző a Garmin Running Clubban. \nA FANKtionAl - REHAB.RUN.WORKOUT - megálmodója és vezetője.",
        instagram: "https://www.instagram.com/godofanka.personaltrainer/?igshid=MzRlODBiNWFlZA==",
      },
      /*
      {
        id: 3,
        name: "Pajor Gábor",
        image: pajor,
        text: "41 éves 4 gyermekes családapa.\nKét évesen már síeltem és korcsolyáztam. Nem tudtam egy pillanatot sem megmaradni a fenekemen, örökmozgó voltam. Játszótéren nőttem fel minden sportot kipróbáltam, amit kavicson, füvön, lejtőn, aszfalton, vízen lehet csinálni. Kérdés sem volt, hogy sportiskolába kerüljek. 6 évesen kerültem be az uszoda klórgőzös világába és ragadtam ott hellyel közzel 12 évet. Közben kipróbáltam több sportot is, de az állandó teljesítmény orientáltságom, minden kipróbált sportban a versenyzés felé vitt. Sí, vitorlázás, tenisz, floorball azok a sportok, amiket hosszabb rövidebb ideig kisebb nagyobb sikerekkel csináltam korai tinédzser éveimben.\nA triatlon a 90es évek elején jött az életembe, amikor az úszást annyira megutáltam, hogy váltanom kellett. (Jó döntésnek tűnik, ha utálom az úszást...:)) Szerencsére a sikerek hamar utolértek így ez lett életem meghatározó vonala.\nA triatlon függőséget okoz. Ami pedig függőséget okoz, az életformát teremt. Szerencsére a sport egy pozitív, legális függőség, így bátran oszthatom meg ezeket a tapasztalatokat másokkal, hogy lendületet adjak e remek hóbort kialakulásához.\nA mai napig nagyon szeretem csinálni és élvezem a sok vele járó pozitív hatást. Nagy lelkesedéssel osztom meg a tapasztalataimat a környezetemmel, és úgy látom, hogy ragadós ez az életforma. Akár komoly amatőr ambíciókkal rendelkező sportolóknak, akár igazi hobbistáknak is tudom ajánlani.",
        instagram: "https://instagram.com/gabor.pajor82?igshid=Mzc1MmZhNjY=",
      },*/
    ],
  }),
  methods: {
    showInfo(id) {
      this.whichCoach = this.coaches.filter((x) => x.id === id)[0];
      this.showCoach = true;
    },
    hideInfo() {
      this.showCoach = false;
    },
  },
};
</script>
