var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "coach info" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", {
        staticClass: "img",
        class: { "no-center": _vm.name == "Gregori Dóra" },
        style: { backgroundImage: "url(" + _vm.image + ")" },
      }),
      _c("div", { staticClass: "textual" }, [
        _c("div", { staticClass: "header" }, [
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.name))]),
          _c(
            "a",
            {
              staticClass: "icon-container",
              attrs: { href: _vm.instagram, target: "_blank" },
            },
            [_c("i", { staticClass: "icon share--instagram" })]
          ),
        ]),
        _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
      ]),
      _c("div", { staticClass: "close" }, [
        _c("i", {
          staticClass: "icon exit",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }