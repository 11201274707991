import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "@/views/Home";
import SuccessView from "@/views/Success";
import CurrentEvent from "@/views/CurrentEvent";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      alias: "/",
      name: "Home",
      component: HomeView,
      redirect: "/nyar",
      children: [
        {
          path: "/nyar",
          name: "nyar",
          component: CurrentEvent,
        },
      ],
    },
    {
      path: "/sikeres",
      name: "Sikeres",
      component: SuccessView,
    },
  ],
});
